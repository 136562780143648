<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{$t('contactPerson.title')}}</h1>
        <contact-person-form
            @submit="onSubmit"
            @cancel="onCancel"
        />
    </div>
</template>

<script>
import ContactPersonForm from './Components/ContactPersonForm.vue'

export default {
    name: 'ContactPersonPage',
    components: {
        ContactPersonForm
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onSubmit () {
            this.$router.push({ name: 'headquartersAddress' })
        },
        onCancel () {
            this.$router.push({ name: 'basicData', params: { type: this.$store.state.userData.basicData.type } })
        }
    }
}
</script>
