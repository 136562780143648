<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <div class="row">
            <div class="col-12 col-md-2">
                <form-input-select
                    v-model="salutation"
                    :id="'contactPerson.salutation'"
                    :label="$t('contactPerson.salutation')"
                    :validation="$v.salutation"
                    :options="salutationOptions"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-2">
                <form-input
                    v-model="prenominal"
                    :label="$t('contactPerson.prenominal')"
                    :id="'contactPerson.prenominal'"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="firstName"
                    :id="'contactPerson.firstName'"
                    :validation="$v.firstName"
                    :label="$t('contactPerson.firstName')"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="lastName"
                    :id="'contactPerson.lastName'"
                    :validation="$v.lastName"
                    :label="$t('contactPerson.lastName')"
                />
            </div>
            <div class="col-12 col-md-2">
                <form-input
                    v-model="postnominal"
                    :id="'contactPerson.postnominal'"
                    :label="$t('contactPerson.postnominal')"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4">
                <form-input-date-picker
                    v-model="dateOfBirth"
                    :id="'contactPerson.dateOfBirth'"
                    :label="$t('contactPerson.dateOfBirth')"
                    :validation="$v.dateOfBirth"
                    :max-date="maxDate"
                    :button-only="true"
                    :date-placeholder="$t('datePlaceholder')"
                />
            </div>
            <div class="col-12 col-md-4">
            </div>
            <div class="col-12 col-md-4">
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                 <form-input
                    v-model="department"
                    :id="'contactPerson.department'"
                    :label="$t('contactPerson.department')"
                />
            </div>
            <div class="col-12 col-md-4">
                 <form-input
                    v-model="position"
                    :id="'contactPerson.position'"
                    :label="$t('contactPerson.position')"
                    :validation="$v.position"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <form-input
                    v-model="email"
                    :id="'contactPerson.email'"
                    :label="$t('contactPerson.email')"
                    :validation="$v.email"
                />
                <form-input
                    v-model="phoneNumber"
                    :id="'contactPerson.phoneNumber'"
                    :label="$t('contactPerson.phoneNumber')"
                    :validation="$v.phoneNumber"
                    :formatter="formatIncompletePhoneNumber"
                    :hint="$t('contactPerson.phoneNumberHint')"
                    :validation-messages="{ isValidNumber: $t('contactPerson.invalidPhoneNumberFormat') }"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="skype"
                    :id="'contactPerson.skype'"
                    :label="$t('contactPerson.skype')"
                />
                <form-input
                    v-model="website"
                    :id="'contactPerson.website'"
                    :label="$t('contactPerson.website')"
                />
            </div>
        </div>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('contactPerson.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('contactPerson.submit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { ContactPerson } from '../../../DTO/'
import { required, email } from 'vuelidate/lib/validators'
import { isValidNumber, formatIncompletePhoneNumber } from 'libphonenumber-js'

export default {
    name: 'ContactPersonForm',
    data () {
        return {
            ...((new ContactPerson(this.$store.state.userData.contactPerson)).toObject())
        }
    },
    computed: {
        maxDate () {
            const date = new Date()
            date.setHours(12, 0, 0, 0)
            date.setDate(date.getDate() + 1)
            return date
        },
        salutationOptions () {
            return JSON.parse(JSON.stringify(this.$store.state.enums.salutationOptions))
        }
    },
    methods: {
        formatIncompletePhoneNumber (value) {
            if (value) {
                return formatIncompletePhoneNumber(value)
            }
            return value
        },
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('contactPerson.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new ContactPerson(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        }
    },
    validations: {
        firstName: {
            required
        },
        lastName: {
            required
        },
        salutation: {
            required
        },
        phoneNumber: {
            required,
            isValidNumber (value) {
                if (value) {
                    return isValidNumber(value)
                }
                return null
            }
        },
        email: {
            required,
            email
        },
        dateOfBirth: {
            required
        },
        position: {
            required
        }
    }
}
</script>
